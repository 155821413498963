.bigTitle {
    @apply flex flex-nowrap place-items-center text-xl md:text-2xl font-semibold;
}

.leftSide {
    @apply flex-grow mx-5 lg:mx-0 lg:mr-10 order-2 lg:order-1;
}
.aside {
    @apply w-full lg:w-96 mx-5 lg:mx-0 order-1 lg:order-2 lg:h-screen lg:sticky lg:top-5;
}

.propertyItemContainer {
    @apply mb-5 rounded-md bg-white border border-gray-300 p-2 text-black shadow-md hover:shadow-lg hover:shadow-sky-100  transition-shadow ease-in-out cursor-pointer;
}

.skeletonContainer {
    @apply mb-5 py-4 rounded-md bg-white border border-gray-300 p-2 text-black shadow-md hover:shadow-lg  hover:shadow-sky-100  transition-shadow ease-in-out cursor-pointer;
}

.propertyMoreItemContainer {
    @apply bg-white border border-gray-300 px-1 text-black transition-shadow ease-in-out cursor-pointer;
}

.skeletonMoreContainer {
    @apply -mb-1 py-2 bg-white border border-gray-300 p-2;
}

.propertyBoxItemContainer {
    @apply rounded-md bg-white border border-gray-300 p-2 text-black shadow-md hover:shadow-lg hover:shadow-sky-100  transition-shadow ease-in-out cursor-pointer;
}

.skeletonBoxContainer {
    @apply mb-5 py-4 rounded-md bg-white border border-gray-300 p-2 text-black shadow-md hover:shadow-lg  hover:shadow-sky-100  transition-shadow ease-in-out cursor-pointer;
}

.asideMiniButton {
    @apply p-1 px-3 border border-dashed border-gray-400 rounded-md shadow-sm hover:shadow-md hover:shadow-blue-100 text-gray-600 bg-slate-50 cursor-pointer;

    small {
        @apply text-gray-400;
    }
    &.active,
    &:hover {
        @apply bg-sky-100 border-solid border-blue-300 text-blue-500;
        small {
            @apply text-blue-400;
        }
    }
}
