.pagination-container {
    display: flex;
    list-style-type: none;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    margin:0;
  
    .pagination-item {
      padding: 0;
      height: 32px;
      place-content: center;
  
      margin: auto 3px;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 700;
      min-width: 30px;
  
      &.dots:hover {
        cursor: default;
      }
      &:hover {
        cursor: pointer;
      }
  
      &.selected {
        font-weight: 700;
        background-color: #009EF7;
        color:#FFFF;
      } 
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

  .paginateDescription
  {
    font-family: 'Inter', sans-serif;
    white-space: nowrap;
    font-size: 14px;
    color:#5B6676;
  }